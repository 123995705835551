import {
  reactive, getCurrentInstance, watch, toRefs,
} from '@vue/composition-api'
import router from '@/router'
// eslint-disable-next-line object-curly-newline

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const getTodayMonthAndYear = () => {
  const today = new Date()
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ]
  const month = monthNames[today.getMonth()]
  const currentYear = today.getFullYear()

  return `${month} ${currentYear}`
}

function formatDate(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const getFirstAndLastDayOfMonth = () => {
  const currentDate = new Date()
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

  // Formatting the dates if needed (optional)
  const formattedFirstDate = formatDate(firstDayOfMonth)
  const formattedLastDate = formatDate(lastDayOfMonth)

  return {
    firstDate: formattedFirstDate || firstDayOfMonth,
    lastDate: formattedLastDate || lastDayOfMonth,
  }
}

export const getTodayDate = () => {
  const today = new Date()
  let day = today.getDate()
  let month = today.getMonth() + 1 // January is 0
  const year = today.getFullYear()

  // Add leading zeros if day or month is a single digit
  if (day < 10) {
    day = `0${day}`
  }
  if (month < 10) {
    month = `0${month}`
  }

  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export const formatNumber = number => {
  // Convert the number to a string
  const strNumber = number.toString()

  // Split the number into integer and decimal parts (if any)
  const parts = strNumber.split('.')
  let integerPart = parts[0]
  const decimalPart = parts.length > 1 ? `.${parts[1]}` : ''

  // Add comma every three digits in the integer part
  const pattern = /(-?\d+)(\d{3})/
  while (pattern.test(integerPart)) {
    integerPart = integerPart.replace(pattern, '$1,$2')
  }

  // Combine the integer and decimal parts
  const formattedNumber = integerPart + decimalPart

  return formattedNumber
}

export const formatNumberToCurrency = number => {
  // Convert the number to a string
  const strNumber = number.toString()

  // Split the number into integer and decimal parts (if any)
  const parts = strNumber.split('.')
  let integerPart = parts[0]
  const decimalPart = parts.length > 1 ? `.${parts[1]}` : ''

  // Add comma every three digits in the integer part
  const pattern = /(-?\d+)(\d{3})/
  while (pattern.test(integerPart)) {
    integerPart = integerPart.replace(pattern, '$1,$2')
  }

  // Combine the integer and decimal parts
  const formattedNumber = integerPart + decimalPart

  return formattedNumber
}

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }
